import React from "react";
import {
  MdLocationOn,
  MdOutlinePhoneIphone,
  MdOutlineKeyboardArrowRight,
} from "react-icons/md";
import { HiOutlineMail } from "react-icons/hi";
import {
  FaFacebookF,
  FaPhoneAlt,
  FaInstagram,
  FaRegClock,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const services = [
  { path: "/emergency", label: "Dental Emergency" },
  { path: "/oral", label: "Oral Checkup" },
  { path: "/scaling", label: "Scaling & Polishing" },
  { path: "/restoration", label: "Restoration" },
  { path: "/rct", label: "Root Canal Treatment" },
  { path: "/xray", label: "Dental Teeth X-Ray" },
  { path: "/crown", label: "Crown Bridge" },
  { path: "/orthodontic", label: "Orthodontic" },
  { path: "/implant", label: "Dental Implant" },
  { path: "/surgery", label: "Dental Surgery" },
];

const hours = [
  { day: "Sunday", time: "8:00 AM-7:30PM" },
  { day: "Monday", time: "8:00 AM-7:30PM" },
  { day: "Tuesday", time: "8:00 AM-7:30PM" },
  { day: "Wednesday", time: "8:00 AM-7:30PM" },
  { day: "Thursday", time: "8:00 AM-7:30PM" },
  { day: "Friday", time: "8:00 AM-7:30PM" },
  { day: "Saturday", time: "8:00 AM-7:30PM" },
];

const Footer = () => {
  return (
    <div
      className="bg-black text-white pt-14"
      data-aos="fade-down"
      data-aos-duration="800"
    >
      <div className="w-[80%] mx-auto flex flex-col lg:flex-row justify-center gap-10">
        {/* About Us Section */}
        <div className="w-full lg:w-[30%]">
          <p className="text-2xl font-bold mb-4">About Us</p>
          <div className="flex flex-col gap-3">
            <p className="flex items-center gap-2">
              <MdLocationOn className="text-2xl" />
              Ghattekulo Chowk, Kathmandu
            </p>
            <p className="flex items-center gap-2">
              <FaPhoneAlt />
              1-4770219
            </p>
            <p className="flex items-center gap-2">
              <MdOutlinePhoneIphone className="text-xl" />
              9843142767, 9816815305
            </p>
            <p className="flex items-center gap-2">
              <HiOutlineMail />
              agrimdentalhome0220@gmail.com
            </p>
          </div>
          <div className="flex gap-4 mt-5">
            <a
              href="https://www.facebook.com/profile.php?id=61554171923866&mibextid=LQQJ4d"
              className="bg-yellow-500 hover:bg-blue-900 p-2 rounded transition duration-300"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
            >
              <FaFacebookF />
            </a>
            <a
              href="https://www.instagram.com/agrim_dental_home_dental_clinc?igsh=dWVrY3d0bWdpeGJw&utm_source=qr"
              className="bg-yellow-500 hover:bg-pink-600 p-2 rounded transition duration-300"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <FaInstagram />
            </a>
          </div>
        </div>

        {/* Services Section */}
        <div className="w-full lg:w-[30%]">
          <p className="text-2xl font-bold mb-4">Services</p>
          <div className="flex flex-col gap-3">
            {services.map((service, index) => (
              <Link
                key={index}
                to={service.path}
                className="flex items-center gap-2 hover:text-yellow-500 transition"
              >
                <MdOutlineKeyboardArrowRight />
                {service.label}
              </Link>
            ))}
          </div>
        </div>

        {/* Opening Hours Section */}
        <div className="w-full lg:w-[30%]">
          <p className="text-2xl font-bold mb-4">Opening Hours</p>
          <div className="flex flex-col gap-2">
            {hours.map((hour, index) => (
              <div key={index} className="flex justify-between">
                <p className="flex items-center gap-2">
                  <FaRegClock />
                  {hour.day}
                </p>
                <p>{hour.time}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="mt-10 pt-7 pb-9 bg-gray-900 text-center text-sm">
        Developed and Designed by{" "}
        <a
          href="https://www.zyluxitsolution.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-green-500 underline"
        >
          Zylux IT Solution
        </a>
        . All Rights Reserved.
      </div>
    </div>
  );
};

export default Footer;
